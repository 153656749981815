import React from 'react'
import App from 'next/app'
import '../css/tailwind.css';

import { config, library } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

import { faBuilding, faBell, faPaperclip, faMap, faMapPin, faSearch, faSort, faAngleRight, faAngleLeft, faStar as fasStar, faEnvelope, faHeart as fasHeart, faRulerCombined, faTimes, faPhoneAlt, faGlobe, faDownload, faSearchPlus, faSearchMinus, faInfoCircle, faChevronRight, faChevronLeft, faBed, faTable, faThList, faMinusSquare, faArrowLeft, faEye, faEyeSlash, faCalendar, faCaretDown, faCaretRight, faMapMarkerAlt, faCheckCircle, faCheck, faSlidersH, faCompass, faEllipsisH, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar, faHeart as farHeart, faBookmark, faBuilding as farBuilding, faFileImage, faFilePdf, faStarHalf, faImages, faClock, faImage, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import { faWhatsapp, faFacebook, faTwitter, faPinterest, faInstagram, faTwitterSquare, faFacebookSquare, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { ParsedUrlQuery } from 'querystring';
import { TRACKING_VARIABLES } from '../utils/ConstData';
import { UnitCompareProvider } from '../lib/providers/UnitCompareProvider';
import { _getCookie } from '../utils/Cookie';

library.add(faBuilding, faEllipsisH, faBell, faPaperclip, faMap, faMapPin, faSearch, faSort, faAngleRight, fasStar, farStar, faEnvelope, faWhatsapp, faFacebook, faTwitter, faPinterest, faInstagram, fasHeart, farHeart, faRulerCombined, faTimes, faPhoneAlt, faAngleLeft, faDownload, faSearchPlus, faSearchMinus, faInfoCircle, faChevronRight, faChevronLeft, faBed, faBookmark, farBuilding, faFileImage, faFilePdf, faTable, faThList, faStarHalf, faMinusSquare, faArrowLeft, faEye, faEyeSlash, faCalendar, faCaretDown, faCaretRight, faMapMarkerAlt, faImages, faCheckCircle, faCheck, faClock, faSlidersH, faCompass, faImage, faCheckSquare, faTwitterSquare, faFacebookSquare, faYoutube, faTiktok, faGlobe, faLongArrowAltRight)

import('react-toastify')
  .then(module => ({ toast: module.toast, Slide: module.Slide }))
  .then(response => {
    const { toast, Slide } = response
    const contextClass = {
      success: "bg-blue-D8E9F0",
      error: "bg-red-7b",
      info: "bg-gray-600",
      warning: "bg-orange-400",
      default: "bg-indigo-600",
      dark: "bg-white-600 font-gray-300",
    }

    toast.configure({
      autoClose: 2000,
      pauseOnFocusLoss: true,
      newestOnTop: true,
      draggable: false,
      limit: 1,
      className: 'w-full md:w-auto lg:-mt-4 p-0',
      toastClassName: (context) => contextClass['default'] + ` ${context?.type === 'error' ? 'text-white' : 'text-black-48'} px-5 min-h-nav-mobile flex flex-row items-center min-w-full md:min-w-375px`,
      bodyClassName: 'pr-2',
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: false,
      transition: Slide,
    })
  })

class MyApp extends App {
  componentDidMount() {
    this.getUtms(this.props.router.query)

    // Handle broken image link
    const imgTag = document.querySelector('img')
    if (imgTag) {
      imgTag.addEventListener('error', function () {
        this.className = this.className + ' invisible'
      })
    }
  }

  componentDidUpdate() {
    this.getUtms(this.props.router.query)
  }

  getUtms(query: ParsedUrlQuery) {
    if (query && Object.keys(query).length > 0) {
      const queryToCookie: { [key: string]: string } = {}
      let addToCookie = false

      var dataReferrer = ''

      if (!document.referrer.includes(`${process.env.NEXT_PUBLIC_MAINSITE_URL}`)) {
        dataReferrer = document.referrer
      }

      TRACKING_VARIABLES.forEach(track => {
        const checkData = track === 'referrer' ? dataReferrer : query[track];
        const regex = /([${}])+/g

        if (checkData && !regex.test(checkData.toString())) {
          queryToCookie[track] = checkData as string;
          addToCookie = true;
        }
      })

      if (queryToCookie.rc) {
        import('../utils/Cookie')
          .then(module => module._setCookie)
          .then(_setCookie => {
            _setCookie('rc_cookie', queryToCookie.rc)
          })
          .catch(error => console.error(error))
        import('axios')
          .then(module => module.default)
          .then(axios => axios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/rc-tracker`, {
            browser_id: _getCookie('browser_id'),
            ref_code: queryToCookie.rc
          }))
          .then(res => res.data)
          .catch(error => console.error(error))
      }

      if (addToCookie) {
        import('../utils/Cookie')
          .then(module => module._setCookie)
          .then(_setCookie => {
            Object.entries(queryToCookie).forEach(([key, value]) => {
              _setCookie(key, value, 2)
            })
          })
          .catch(error => console.error(error))
      }
    }
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <UnitCompareProvider units={[]}>
        <Component {...pageProps} />
      </UnitCompareProvider>
    )
  }
}

export default MyApp