import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"

export interface UnitForCompare {
    id: string
    name: string
    cluster_name: string
    img: string
    url: string
    category: string
}

interface IProps {
    children: React.ReactChild
    units: []
}

interface IState {
    units: UnitForCompare[]
    addUnit: (unit: UnitForCompare) => void
    removeUnit: (index: number, isFromComparePage?: boolean) => void
    changeUnit: (unit: UnitForCompare, index: number) => void
    addedCounter: number
}

interface IValue extends IState {
    isFull: boolean
    isCompared: (id: string) => boolean
    currentCategory: string
    isInit: boolean
}

export const UnitCompareProvider = (props: IProps) => {
    const addUnit = (unit: UnitForCompare) => {
        setState(prev => {
            const isNotExist = prev.units.filter(stateUnit => stateUnit.id === unit.id).length === 0
            const isNotMax = prev.units.length < 3

            if (isNotExist && !isNotMax) {
                toast("Only 3 units can be compared", {
                    closeButton: false,
                    type: 'error',
                    autoClose: 3000,
                    // position: 'top-right',
                    className: 'rounded mb-10 xs:px-2 md:px-8 text-center mx-auto',
                    style: { color: '#fff', width: 'unset' }
                })
            }

            return ({
                ...prev,
                units: (isNotExist && isNotMax) ? prev.units.concat(unit) : prev.units,
                addedCounter: prev.addedCounter + 1,
            })
        })
    }

    const removeUnit = (index: number, isFromComparePage?: boolean) => {
        setState(prev => ({
            ...prev,
            units: prev.units.filter((unit, idx) => idx !== index),
        }))
    }

    const changeUnit = (unit: UnitForCompare, index: number) => {
        setState(prev => ({
            ...prev,
            units: prev.units.map((oldUnit, idx) => idx === index ? unit : oldUnit),
        }))
    }

    const [state, setState] = useState<IState>({
        units: props.units,
        addUnit,
        removeUnit,
        changeUnit,
        addedCounter: 0,
    })

    const isFull = useMemo(() => state.units.length >= 3, [state.units])

    const [isInit, setIsInit] = useState(true)

    useEffect(() => {
        if (!isInit) localStorage.setItem('compare-unit', JSON.stringify(state.units))
    }, [state.units])

    useEffect(() => {
        const storageListener = () => {
            getFromLocalStorage()
        }

        window.addEventListener('storage', storageListener)

        getFromLocalStorage()

        setIsInit(false)

        return () => {
            window.removeEventListener('storage', storageListener)
        }
    }, [])

    const getFromLocalStorage = () => {
        const localStorageUnits = JSON.parse(localStorage.getItem('compare-unit') || '[]')

        setState(prev => ({
            ...prev,
            units: localStorageUnits,
        }))
    }

    const isCompared = useCallback((id: string) => {
        return state.units.filter(unit => unit.id === id).length > 0
    }, [state.units])

    const currentCategory = useMemo(() => state.units[0]?.category || '', [state.units])

    return (
        <UnitCompareContext.Provider value={{
            ...state,
            isFull,
            isCompared,
            currentCategory,
            isInit,
        }}>
            { props.children }
        </UnitCompareContext.Provider>
    )
}

export const UnitCompareContext = createContext<IValue>({
    units: [],
    addUnit: () => {},
    removeUnit: () => {},
    changeUnit: () => {},
    addedCounter: 0,
    isFull: false,
    isCompared: () => false,
    currentCategory: '',
    isInit: true,
})

export const UnitCompareConsumer = UnitCompareContext.Consumer
export const useUnitCompare = () => useContext(UnitCompareContext)
