export const TRACKING_VARIABLES = [
    "rc",
    "utm_id",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "is_wfh",
];

export const HIDE_BANNER_PROJECT_BASED_ON_SLUG = [
    'akasa',
    'upper-west',
]

export const HIDE_BANNER_CLUSTER_BASED_ON_PROJECT_SLUG = [
    'akasa',
    'upper-west',
    'bsd-commercial',
]

export const HIDE_BANNER_LISTING_BASED_ON_PROJECT_SLUG = [
    'akasa',
    'upper-west',
    'bsd-commercial',
]